import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { MapMarker } from '../utils/types';
import { GOOGLE_MAPS_API_KEY, googleMapsLibraries, MapsService } from '../services/mapsService';

interface MapProps {
  markers: MapMarker[];
  showDirections?: boolean;
  onMapLoad?: (map: google.maps.Map) => void;
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '0.5rem'
};

const center = {
  lat: 31.7917,
  lng: -6.0
};

const mapOptions: google.maps.MapOptions = {
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: true,
  rotateControl: true,
  fullscreenControl: true,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ]
};

const Map: React.FC<MapProps> = ({ markers, showDirections = false, onMapLoad }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
  const mapsService = MapsService.getInstance();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapsLibraries
  });

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
    if (onMapLoad) onMapLoad(map);
  }, [onMapLoad]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (!isLoaded || !showDirections || markers.length < 2) return;

    const fetchDirections = async () => {
      try {
        const origin = `${markers[0].coordinates[1]},${markers[0].coordinates[0]}`;
        const destination = `${markers[markers.length - 1].coordinates[1]},${markers[markers.length - 1].coordinates[0]}`;
        const waypoints = markers.slice(1, -1).map(marker => 
          `${marker.coordinates[1]},${marker.coordinates[0]}`
        );

        const result = await mapsService.getDirections(origin, destination, waypoints);
        setDirections(result);

        // Fit bounds to show the entire route
        if (map && result.routes[0]?.bounds) {
          map.fitBounds(result.routes[0].bounds);
        }
      } catch (error) {
        console.error('Error fetching directions:', error);
      }
    };

    fetchDirections();
  }, [isLoaded, markers, showDirections, map, mapsService]);

  if (!isLoaded) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-lg">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#79BFAF]"></div>
      </div>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={5}
      options={mapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {!showDirections && markers.map((marker, index) => (
        <Marker
          key={index}
          position={{
            lat: marker.coordinates[1],
            lng: marker.coordinates[0]
          }}
          title={marker.name}
        />
      ))}
      {showDirections && directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            suppressMarkers: false,
            polylineOptions: {
              strokeColor: '#79BFAF',
              strokeWeight: 4
            }
          }}
        />
      )}
    </GoogleMap>
  );
};

export default Map;