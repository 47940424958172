import React from 'react';
import { TripResult } from '../utils/types';
import { useNavigate } from 'react-router-dom';
import Map from './Map';

interface TripResultsProps {
  trips: TripResult[];
  onBack: () => void;
}

const TripResults: React.FC<TripResultsProps> = ({ trips, onBack }) => {
  const navigate = useNavigate();

  // Helper function to clean up title
  const formatTitle = (title: string) => {
    return title
      .split(',')
      .filter(part => part.trim() !== '') // Remove empty parts
      .join(': ')
      .replace(/:{2,}/g, ':') // Replace multiple colons with single colon
      .trim();
  };

  // Helper function to format duration
  const formatDuration = (duration: { min: number; max: number }) => {
    const min = Number(duration?.min);
    const max = Number(duration?.max);
    
    if (isNaN(min) && isNaN(max)) {
      return '7-14 days'; // Default duration if both are invalid
    }
    
    if (isNaN(min)) {
      return `${max} days`;
    }
    
    if (isNaN(max)) {
      return `${min} days`;
    }
    
    if (min === max) {
      return `${min} days`;
    }
    
    return `${min}-${max} days`;
  };

  if (!trips.length) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-lg">
        <h3 className="text-2xl font-semibold text-[#D1683F] mb-4">No Trips Found</h3>
        <p className="text-gray-600 text-center mb-6">
          Try adjusting your preferences to find more matching trips
        </p>
        <button
          onClick={onBack}
          className="px-6 py-3 bg-[#79BFAF] text-white rounded-lg hover:bg-[#79BFAF]/90 transition-all duration-300"
        >
          Modify Preferences
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-[#D1683F]">
          Found {trips.length} {trips.length === 1 ? 'Trip' : 'Trips'}
        </h2>
        <button
          onClick={onBack}
          className="px-4 py-2 bg-[#79BFAF] text-white rounded-lg hover:bg-[#79BFAF]/90 transition-all duration-300"
        >
          Back to Search
        </button>
      </div>
      
      <div className="grid md:grid-cols-2 gap-6">
        <div className="space-y-6">
          {trips.map((trip) => (
            <div
              key={trip.id}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-semibold text-[#D1683F]">
                  {formatTitle(trip.name)}
                </h3>
                <span className="px-3 py-1 bg-[#79BFAF]/10 text-[#79BFAF] rounded-full text-sm font-medium">
                  {trip.matchScore ? `${Math.min(Math.round(trip.matchScore * 100), 100)}% Match` : 'New Trip'}
                </span>
              </div>
              <p className="text-gray-600 mb-4">{trip.description}</p>
              <div className="flex flex-wrap gap-2 mb-4">
                {trip.themes.map((theme: string, themeIndex: number) => (
                  <span
                    key={themeIndex}
                    className="px-3 py-1 bg-[#F6E6CC] text-[#D1683F] rounded-full text-sm"
                  >
                    {theme}
                  </span>
                ))}
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">
                  <span className="font-medium">
                    {formatDuration(trip.duration)}
                  </span>
                </span>
                <button
                  onClick={() => navigate(`/trip/${trip.id}`)}
                  className="px-4 py-2 bg-[#79BFAF] text-white rounded-lg hover:bg-[#79BFAF]/90 transition-all duration-300"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="h-[600px] sticky top-6">
          <Map
            markers={trips.map(trip => ({
              coordinates: trip.coordinates || [0, 0],
              name: trip.name
            }))}
            showDirections={trips.length === 1}
          />
        </div>
      </div>
      <div className="mt-12 bg-gradient-to-br from-[#F6E6CC] to-white rounded-2xl p-8 shadow-lg">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-[#D1683F] mb-4">
              Make Your Moroccan Adventure a Reality
            </h2>
            <p className="text-lg text-gray-700 mb-6">
              Turn these curated itineraries into unforgettable memories with our premium camper van rentals
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-6 mb-8">
            <div className="bg-white p-6 rounded-xl shadow-md">
              <div className="text-[#79BFAF] text-2xl font-bold mb-3">✦</div>
              <h3 className="text-xl font-semibold text-[#D1683F] mb-2">Freedom to Explore</h3>
              <p className="text-gray-600">
                Go beyond the typical tourist spots. Our fully-equipped camper vans give you the freedom to discover Morocco's hidden gems at your own pace.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md">
              <div className="text-[#79BFAF] text-2xl font-bold mb-3">✦</div>
              <h3 className="text-xl font-semibold text-[#D1683F] mb-2">Comfort & Adventure</h3>
              <p className="text-gray-600">
                Experience the perfect blend of comfort and adventure with our modern, well-maintained fleet of camper vans designed for Moroccan terrain.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-md">
              <div className="text-[#79BFAF] text-2xl font-bold mb-3">✦</div>
              <h3 className="text-xl font-semibold text-[#D1683F] mb-2">Local Expertise</h3>
              <p className="text-gray-600">
                Benefit from our deep local knowledge, 24/7 support, and carefully planned routes optimized for camper van travel.
              </p>
            </div>
          </div>
          
          <div className="bg-white rounded-xl p-8 shadow-md">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-6 md:mb-0 md:mr-8">
                <h3 className="text-2xl font-bold text-[#D1683F] mb-3">
                  Special Offer for Trip Planners
                </h3>
                <p className="text-lg text-gray-700 mb-4">
                  Book now and get <span className="font-semibold">15% off</span> your camper van rental + free camping gear!
                </p>
                <ul className="text-gray-600 mb-6">
                  <li className="flex items-center mb-2">
                    <span className="text-[#79BFAF] mr-2">✓</span> Fully equipped kitchen
                  </li>
                  <li className="flex items-center mb-2">
                    <span className="text-[#79BFAF] mr-2">✓</span> Solar power system
                  </li>
                  <li className="flex items-center mb-2">
                    <span className="text-[#79BFAF] mr-2">✓</span> Comfortable sleeping area
                  </li>
                  <li className="flex items-center">
                    <span className="text-[#79BFAF] mr-2">✓</span> 24/7 roadside assistance
                  </li>
                </ul>
              </div>
              <div className="flex flex-col items-center">
                <div className="text-center mb-4">
                  <p className="text-sm text-gray-500 mb-1">Starting from</p>
                  <p className="text-3xl font-bold text-[#D1683F]">€89<span className="text-lg font-normal">/day</span></p>
                </div>
                <button 
                  onClick={() => window.open('https://your-booking-url.com', '_blank')}
                  className="w-full px-8 py-4 bg-[#D1683F] text-white rounded-lg font-semibold text-lg hover:bg-[#D1683F]/90 transform hover:scale-105 transition-all duration-300 shadow-lg"
                >
                  Book Your Adventure Now
                </button>
                <p className="text-sm text-gray-500 mt-3">
                  Limited time offer • No hidden fees
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripResults;