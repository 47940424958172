import React from 'react';
import { theme } from '../../styles/theme';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold text-[#D1683F] mb-8">Privacy Policy</h1>
      
      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">1. Data Collection</h2>
          <p>We collect the following types of information:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Account information (email, name)</li>
            <li>Travel preferences</li>
            <li>Usage data and analytics</li>
            <li>Device information</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">2. Use of Data</h2>
          <p>We use your data to:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Provide personalized trip recommendations</li>
            <li>Improve our services</li>
            <li>Communicate with you about your account</li>
            <li>Ensure security of our platform</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">3. Data Protection</h2>
          <p>We implement various security measures to protect your data:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Encryption of sensitive data</li>
            <li>Regular security audits</li>
            <li>Access controls and authentication</li>
            <li>Secure data storage</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">4. Your Rights</h2>
          <p>Under GDPR, you have the right to:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Access your personal data</li>
            <li>Correct inaccurate data</li>
            <li>Request data deletion</li>
            <li>Export your data</li>
            <li>Object to data processing</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">5. Cookies</h2>
          <p>We use cookies to:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Maintain your session</li>
            <li>Remember your preferences</li>
            <li>Analyze usage patterns</li>
            <li>Improve user experience</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">6. Contact Us</h2>
          <p>For any privacy-related questions or requests, contact us at:</p>
          <p className="mt-2">privacy@babacamper.com</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">7. Updates</h2>
          <p>This privacy policy was last updated on {new Date().toLocaleDateString()}.</p>
          <p>We may update this policy periodically. Check back regularly for updates.</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
