import { LoadScriptProps } from '@react-google-maps/api';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBMP4coRDobiTBfTHIchs8r7cgX0mbk3xI';

export const googleMapsLibraries: LoadScriptProps['libraries'] = ['places', 'geometry', 'drawing'];

interface CacheItem {
  data: any;
  timestamp: number;
}

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

export class MapsService {
  private static instance: MapsService;
  private quotaUsage: Record<string, number> = {};
  private readonly storageKey = 'maps_api_quota';

  private constructor() {
    this.loadQuota();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new MapsService();
    }
    return this.instance;
  }

  private loadQuota() {
    const saved = localStorage.getItem(this.storageKey);
    if (saved) {
      const { usage, date } = JSON.parse(saved);
      if (new Date(date).getDate() !== new Date().getDate()) {
        this.quotaUsage = {};
      } else {
        this.quotaUsage = usage;
      }
    }
    this.saveQuota();
  }

  private saveQuota() {
    localStorage.setItem(this.storageKey, JSON.stringify({
      usage: this.quotaUsage,
      date: new Date()
    }));
  }

  async checkQuota(apiType: string): Promise<boolean> {
    const DAILY_QUOTA: Record<string, number> = {
      geocoding: 500,
      directions: 300,
      places: 1000
    };

    this.quotaUsage[apiType] = (this.quotaUsage[apiType] || 0) + 1;
    this.saveQuota();
    return this.quotaUsage[apiType] <= (DAILY_QUOTA[apiType] || 0);
  }

  createCacheKey(type: string, params: string): string {
    return `maps_${type}_${params}`;
  }

  async getCachedData(key: string): Promise<any> {
    const cached = localStorage.getItem(key);
    if (cached) {
      const item: CacheItem = JSON.parse(cached);
      if (Date.now() - item.timestamp < CACHE_DURATION) {
        return item.data;
      }
    }
    return null;
  }

  async setCachedData(key: string, data: any): Promise<void> {
    const item: CacheItem = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  async getDirections(origin: string, destination: string, waypoints: string[] = []) {
    if (!await this.checkQuota('directions')) {
      throw new Error('Daily directions quota exceeded');
    }

    const cacheKey = this.createCacheKey('directions', `${origin}-${destination}-${waypoints.join('-')}`);
    const cached = await this.getCachedData(cacheKey);
    if (cached) return cached;

    const directionsService = new google.maps.DirectionsService();
    
    try {
      const result = await directionsService.route({
        origin,
        destination,
        waypoints: waypoints.map(point => ({
          location: point,
          stopover: true
        })),
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      });

      await this.setCachedData(cacheKey, result);
      return result;
    } catch (error) {
      console.error('Error getting directions:', error);
      throw error;
    }
  }

  async searchPlaces(query: string, location?: google.maps.LatLng) {
    if (!await this.checkQuota('places')) {
      throw new Error('Daily places quota exceeded');
    }

    const cacheKey = this.createCacheKey('places', `${query}-${location?.toString()}`);
    const cached = await this.getCachedData(cacheKey);
    if (cached) return cached;

    return new Promise((resolve, reject) => {
      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      );

      const request: google.maps.places.TextSearchRequest = {
        query,
        location,
        radius: 50000 // 50km radius
      };

      service.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          this.setCachedData(cacheKey, results);
          resolve(results);
        } else {
          reject(new Error(`Places search failed: ${status}`));
        }
      });
    });
  }
}
