import React, { useState } from 'react';
import Select from 'react-select';
import { TripResult, TripFormData } from '../utils/types';
import TripResults from './TripResults';
import { getMatchedTrips } from '../services/tripService';

const TripPlanner: React.FC = () => {
  const [showResults, setShowResults] = useState(false);
  const [trips, setTrips] = useState<TripResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<TripFormData>({
    themes: [],
    duration: '',
    regions: [],
    groupSize: ''
  });

  const themeOptions = [
    { value: 'art-history-culture', label: '🏛️ Art, History & Cultural' },
    { value: 'adventure-trekking', label: '🏃‍♂️ Adventure & Trekking' },
    { value: 'cultural-immersion', label: '👥 Local Culture & Immersion' },
    { value: 'desert-exploration', label: '🐪 Desert Exploration' },
    { value: 'sea-beaches', label: '🏖️ Sea & Beaches' },
    { value: 'urban-exploration', label: '🏙️ Urban Exploration' },
    { value: 'nature-photography', label: '📸 Nature & Photography' },
    { value: 'off-beaten-path', label: '🗺️ Off the Beaten Path' }
  ];

  const regionOptions = [
    { value: 'marrakech', label: '🏰 Marrakech Region' },
    { value: 'fes', label: '🕌 Fes Region' },
    { value: 'tangier', label: '🌊 Tangier Region' },
    { value: 'agadir', label: '🏖️ Agadir Region' },
    { value: 'ouarzazate', label: '🎬 Ouarzazate Region' },
    { value: 'dakhla', label: '🏄‍♂️ Dakhla Region' },
    { value: 'rabat', label: '👑 Rabat Region' },
    { value: 'casablanca', label: '🌆 Casablanca Region' }
  ];

  const durationOptions = [
    { value: '3-5', label: '3-5 days (Short Trip)' },
    { value: '6-8', label: '6-8 days (Medium Trip)' },
    { value: '9-11', label: '9-11 days (Long Trip)' },
    { value: '12-15', label: '12-15 days (Extended Trip)' }
  ];

  const groupSizeOptions = [
    { value: 'solo', label: '🚶 Solo Traveler' },
    { value: 'couple', label: '👫 Couple' },
    { value: 'family', label: '👨‍👩‍👧‍👦 Family (3-6 people)' },
    { value: 'group', label: '👥 Group (6+ people)' }
  ];

  const customSelectStyles = {
    control: (base: any) => ({
      ...base,
      background: 'white',
      borderColor: '#E2E8F0',
      borderRadius: '0.75rem',
      padding: '0.25rem',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#79BFAF',
      },
    }),
    option: (base: any, state: { isSelected: boolean }) => ({
      ...base,
      backgroundColor: state.isSelected ? '#79BFAF' : base.backgroundColor,
      '&:hover': {
        backgroundColor: state.isSelected ? '#79BFAF' : '#F6E6CC',
      },
    }),
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Validate form data
    if (!formData.themes.length || !formData.duration || !formData.regions.length || !formData.groupSize) {
      setError('Please fill in all fields');
      setLoading(false);
      return;
    }

    try {
      // Find the full option objects from the select options
      const transformedFormData = {
        themes: formData.themes.map(themeValue => 
          themeOptions.find(opt => opt.value === themeValue) || { value: themeValue, label: themeValue }
        ),
        duration: durationOptions.find(opt => opt.value === formData.duration) || { value: formData.duration, label: formData.duration },
        regions: formData.regions.map(regionValue => 
          regionOptions.find(opt => opt.value === regionValue) || { value: regionValue, label: regionValue }
        ),
        groupSize: groupSizeOptions.find(opt => opt.value === formData.groupSize) || { value: formData.groupSize, label: formData.groupSize }
      };

      const matchedTrips = await getMatchedTrips(transformedFormData);
      setTrips(matchedTrips);
      setShowResults(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch trips');
      console.error('Error fetching trips:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setShowResults(false);
  };

  if (showResults) {
    return <TripResults trips={trips} onBack={handleBack} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F6E6CC]/30 to-white px-4 py-8 md:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-4xl md:text-5xl font-bold text-[#D1683F] mb-4">
            Design Your Perfect Journey
          </h1>
          <p className="text-lg text-gray-600">
            Tell us your travel preferences, and we'll craft the ideal Moroccan adventure for you
          </p>
        </div>

        <form 
          onSubmit={handleSubmit}
          className="bg-white rounded-2xl shadow-xl p-6 md:p-8 space-y-8"
        >
          <div className="space-y-6">
            <div className="relative">
              <label className="block text-lg font-semibold text-[#D1683F] mb-3">
                Travel Theme
              </label>
              <Select
                isMulti
                options={themeOptions}
                styles={customSelectStyles}
                className="text-base"
                placeholder="What interests you most?"
                onChange={(selected) => {
                  setFormData(prev => ({
                    ...prev,
                    themes: selected ? selected.map(option => option.value) : []
                  }));
                }}
              />
            </div>

            <div className="relative">
              <label className="block text-lg font-semibold text-[#D1683F] mb-3">
                Trip Duration
              </label>
              <Select
                options={durationOptions}
                styles={customSelectStyles}
                className="text-base"
                placeholder="How long would you like to travel?"
                onChange={(selected) => {
                  setFormData(prev => ({
                    ...prev,
                    duration: selected ? selected.value : ''
                  }));
                }}
              />
            </div>

            <div className="relative">
              <label className="block text-lg font-semibold text-[#D1683F] mb-3">
                Preferred Regions
              </label>
              <Select
                isMulti
                options={regionOptions}
                styles={customSelectStyles}
                className="text-base"
                placeholder="Where would you like to explore?"
                onChange={(selected) => {
                  setFormData(prev => ({
                    ...prev,
                    regions: selected ? selected.map(option => option.value) : []
                  }));
                }}
              />
            </div>

            <div className="relative">
              <label className="block text-lg font-semibold text-[#D1683F] mb-3">
                Group Size
              </label>
              <Select
                options={groupSizeOptions}
                styles={customSelectStyles}
                className="text-base"
                placeholder="Who are you traveling with?"
                onChange={(selected) => {
                  setFormData(prev => ({
                    ...prev,
                    groupSize: selected ? selected.value : ''
                  }));
                }}
              />
            </div>
          </div>

          {error && (
            <div className="text-red-500 text-center py-3 bg-red-50 rounded-lg">
              {error}
            </div>
          )}

          <div className="pt-6">
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-[#D1683F] text-white rounded-xl py-4 px-6 text-lg font-semibold
                hover:bg-[#D1683F]/90 transform hover:scale-[1.02] transition-all duration-300
                disabled:opacity-50 disabled:cursor-not-allowed shadow-lg"
            >
              {loading ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  <span>Crafting Your Journey...</span>
                </div>
              ) : (
                'Generate My Adventure'
              )}
            </button>
          </div>
        </form>

        <div className="mt-8 text-center text-sm text-gray-500">
          <p>All adventures are customized to your preferences and optimized for camper van travel</p>
        </div>
      </div>
    </div>
  );
};

export default TripPlanner;