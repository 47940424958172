import { TripFormData, TripResult } from '../utils/types';
import DOMPurify from 'dompurify';
import { auth } from '../lib/firebase';

// Rate limiting configuration
const RATE_LIMIT = {
  MAX_REQUESTS: 100,
  TIME_WINDOW: 3600000, // 1 hour in milliseconds
};

class RateLimiter {
  private requests: { [key: string]: number[] } = {};

  isRateLimited(userId: string): boolean {
    const now = Date.now();
    if (!this.requests[userId]) {
      this.requests[userId] = [];
    }

    // Remove old requests
    this.requests[userId] = this.requests[userId].filter(
      (timestamp) => now - timestamp < RATE_LIMIT.TIME_WINDOW
    );

    // Check if user has exceeded rate limit
    if (this.requests[userId].length >= RATE_LIMIT.MAX_REQUESTS) {
      return true;
    }

    // Add new request
    this.requests[userId].push(now);
    return false;
  }
}

export const rateLimiter = new RateLimiter();

// Data sanitization
export const sanitizeInput = (input: string): string => {
  return DOMPurify.sanitize(input.trim(), {
    ALLOWED_TAGS: [], // No HTML allowed
    ALLOWED_ATTR: [], // No attributes allowed
  });
};

// Input validation
export const validateTripFormData = (data: TripFormData): { isValid: boolean; errors: string[] } => {
  const errors: string[] = [];

  // Validate regions
  if (!data.regions || data.regions.length === 0) {
    errors.push('At least one region must be selected');
  } else {
    data.regions.forEach(region => {
      if (!region.value || !region.label) {
        errors.push('Invalid region format');
      }
    });
  }

  // Validate themes
  if (!data.themes || data.themes.length === 0) {
    errors.push('At least one theme must be selected');
  } else {
    data.themes.forEach(theme => {
      if (!theme.value || !theme.label) {
        errors.push('Invalid theme format');
      }
    });
  }

  // Validate duration
  if (data.duration) {
    const duration = parseInt(data.duration.value);
    if (isNaN(duration) || duration < 1 || duration > 30) {
      errors.push('Duration must be between 1 and 30 days');
    }
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
};

// Security checks
export const securityChecks = {
  isAuthenticated: (): boolean => {
    return !!auth.currentUser;
  },

  isEmailVerified: (): boolean => {
    return !!auth.currentUser?.emailVerified;
  },

  hasRequiredPermissions: (requiredPermissions: string[]): boolean => {
    const userClaims = auth.currentUser?.getIdTokenResult()
      .then(idTokenResult => idTokenResult.claims)
      .catch(() => ({}));
    
    return requiredPermissions.every(permission => userClaims[permission]);
  },

  validateCSRFToken: (token: string): boolean => {
    // Implement CSRF token validation
    // This should match the token stored in the user's session
    return true; // Placeholder implementation
  }
};

// GDPR compliance helpers
export const gdprCompliance = {
  getUserData: async (userId: string): Promise<any> => {
    // Implement fetching all user data for GDPR compliance
    // This should include all personal data stored about the user
    return {};
  },

  deleteUserData: async (userId: string): Promise<void> => {
    // Implement complete user data deletion for GDPR compliance
    // This should delete all personal data associated with the user
  },

  exportUserData: async (userId: string): Promise<string> => {
    // Implement user data export for GDPR compliance
    // This should return all user data in a portable format
    const userData = await gdprCompliance.getUserData(userId);
    return JSON.stringify(userData, null, 2);
  },

  logDataAccess: (userId: string, purpose: string): void => {
    // Log all data access for GDPR compliance
    console.log(`Data access: User ${userId} for purpose: ${purpose}`);
  }
};

// Error handling
export class SecurityError extends Error {
  constructor(
    message: string,
    public code: string,
    public httpStatus: number = 403
  ) {
    super(message);
    this.name = 'SecurityError';
  }
}

// Security headers
export const securityHeaders = {
  'Content-Security-Policy': "default-src 'self' https://identitytoolkit.googleapis.com https://road-trip-planner-cd929.firebaseapp.com https://firestore.googleapis.com; script-src 'self' 'unsafe-inline' https://apis.google.com; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com; font-src 'self' https://fonts.gstatic.com; img-src 'self' https://images.unsplash.com data: https:; connect-src 'self' https://identitytoolkit.googleapis.com https://firestore.googleapis.com; frame-src 'self' https://road-trip-planner-cd929.firebaseapp.com",
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'DENY',
  'X-XSS-Protection': '1; mode=block',
  'Referrer-Policy': 'strict-origin-when-cross-origin',
  'Permissions-Policy': 'geolocation=(self), camera=(), microphone=()'
};
