export const theme = {
  colors: {
    primary: '#D1683F',    // Warm orange - main brand color
    secondary: '#79BFAF',  // Teal - secondary brand color
    accent: '#F6E6CC',     // Soft beige - accent color
    white: '#FFFFFF',      // White
    text: {
      primary: '#2D3748',  // Dark gray for primary text
      secondary: '#4A5568' // Medium gray for secondary text
    },
    background: {
      light: '#FFFFFF',
      subtle: '#F7FAFC'
    }
  },
  typography: {
    fontFamily: {
      primary: 'Poppins, sans-serif',
      secondary: 'Poppins, sans-serif'
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    sizes: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem'
    }
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    '2xl': '3rem'
  },
  borderRadius: {
    sm: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    full: '9999px'
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)'
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  },
  transitions: {
    default: '0.3s ease-in-out',
    fast: '0.15s ease-in-out',
    slow: '0.5s ease-in-out'
  }
};
