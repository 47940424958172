import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { TripResult, TripFormData } from '../utils/types';

const tripsCollection = collection(db, 'trips');

// Unsplash Collections for Morocco
const UNSPLASH_COLLECTIONS = {
  desert: 'https://images.unsplash.com/photo-1597346908500-28d43339c725',
  medina: 'https://images.unsplash.com/photo-1553165558-b4f6fb9c91a4',
  coast: 'https://images.unsplash.com/photo-1579017308347-e53e0d2fc5e9',
  mountains: 'https://images.unsplash.com/photo-1595962271469-d0de84c13efc',
  culture: 'https://images.unsplash.com/photo-1489749798305-4fea3ae63d43'
};

// Simple in-memory cache
const cache = {
  allTrips: null as TripResult[] | null,
  lastFetch: 0,
  CACHE_DURATION: 5 * 60 * 1000, // 5 minutes
};

interface ScoredTrip extends TripResult {
  matchScore: number;
  matchReason: string;
  hasRegionMatch: boolean;
}

// Get appropriate Unsplash image based on trip themes
function getUnsplashImage(themes: string[]): string {
  const normalizedThemes = themes.map(theme => theme.toLowerCase());
  
  if (normalizedThemes.includes('desert') || normalizedThemes.includes('sahara')) {
    return UNSPLASH_COLLECTIONS.desert;
  }
  if (normalizedThemes.includes('medina') || normalizedThemes.includes('city')) {
    return UNSPLASH_COLLECTIONS.medina;
  }
  if (normalizedThemes.includes('coast') || normalizedThemes.includes('beach')) {
    return UNSPLASH_COLLECTIONS.coast;
  }
  if (normalizedThemes.includes('mountains') || normalizedThemes.includes('atlas')) {
    return UNSPLASH_COLLECTIONS.mountains;
  }
  return UNSPLASH_COLLECTIONS.culture; // default to culture image
}

// Fetch all trips with caching
async function getAllTrips(): Promise<TripResult[]> {
  const now = Date.now();
  
  // Return cached data if it's still valid
  if (cache.allTrips && (now - cache.lastFetch) < cache.CACHE_DURATION) {
    console.log('Returning cached trips:', cache.allTrips.length);
    return cache.allTrips;
  }

  console.log('Fetching trips from Firebase...');
  const querySnapshot = await getDocs(query(tripsCollection));
  console.log('Got querySnapshot, docs length:', querySnapshot.docs.length);
  
  const trips = querySnapshot.docs.map(doc => {
    const data = doc.data() as TripResult;
    return {
      ...data,
      id: doc.id,
      imageUrl: data.imageUrl || getUnsplashImage(data.themes)
    };
  });
  
  console.log('Processed trips:', trips.length);
  if (trips.length > 0) {
    console.log('Sample trip:', JSON.stringify(trips[0], null, 2));
  }
  
  // Update cache
  cache.allTrips = trips;
  cache.lastFetch = now;
  
  return trips;
}

// Check if a trip's route includes a specific region/city
function hasRegionInRoute(trip: TripResult, region: string): boolean {
  if (!region || !trip.name) return false;
  
  const normalizedRegion = region.toLowerCase().trim();
  const tripName = trip.name.toLowerCase();
  
  // Extract cities from the route (format: "Title: CityA to CityB to CityC")
  const routePart = tripName.split(':')[1];
  if (!routePart) return false;
  
  const cities = routePart
    .split('to')
    .map(city => city.trim())
    .filter(Boolean);
  
  // Check if any city in the route matches the region
  const cityMatch = cities.some(city => 
    city.includes(normalizedRegion) || 
    normalizedRegion.includes(city)
  );
  
  // Also check the trip's region field
  const regionMatch = trip.region?.toLowerCase().includes(normalizedRegion);
  
  return cityMatch || regionMatch;
}

// Calculate match score between user preferences and trip
function calculateTripScore(trip: TripResult, preferences: TripFormData): { score: number; matchReason: string } {
  let score = 0;
  let reasons: string[] = [];

  // 1. Region/City matching (REQUIRED - acts as a filter)
  const regionMatches = preferences.regions.some(region => {
    if (!region?.value) return false;
    const normalizedRegion = region.value.toLowerCase();
    const tripRegion = (trip.region || '').toLowerCase();
    return tripRegion.includes(normalizedRegion) || normalizedRegion.includes(tripRegion);
  });

  // If no region matches, return zero score immediately
  if (!regionMatches) {
    return { score: 0, matchReason: 'No matching destinations found' };
  }

  // Add region match to score and reasons
  score += 50; // Base score for matching region
  reasons.push(`Matches your selected destination${preferences.regions.length > 1 ? 's' : ''}`);

  // 2. Theme matching (second priority - up to 30 points)
  const matchingThemes = preferences.themes.filter(theme => {
    if (!theme?.value || !trip.themes) return false;
    // Convert adventure-trekking to Adventure & Trekking
    const normalizedPreferenceTheme = theme.value
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' & ');
    
    return trip.themes.some(tripTheme => {
      if (!tripTheme) return false;
      return tripTheme.includes(normalizedPreferenceTheme) || 
             normalizedPreferenceTheme.includes(tripTheme) ||
             tripTheme === 'Adventure'; // Match 'Adventure' as a fallback
    });
  });

  if (matchingThemes.length > 0) {
    const themeScore = Math.min(30, matchingThemes.length * 10); // Cap at 30 points
    score += themeScore;
    reasons.push(`Includes ${matchingThemes.length} of your preferred themes`);
  }

  // 3. Duration matching (third priority - up to 15 points)
  if (preferences.duration?.value && trip.duration) {
    const [prefMin, prefMax] = preferences.duration.value.split('-').map(Number);
    const tripMin = trip.duration.min || trip.duration.recommended;
    const tripMax = trip.duration.max || trip.duration.recommended;
    
    // Handle cases where we have at least one valid duration value
    if (!isNaN(tripMin) || !isNaN(tripMax)) {
      const tripDuration = isNaN(tripMin) ? tripMax : (isNaN(tripMax) ? tripMin : (tripMin + tripMax) / 2);
      
      if (!isNaN(tripDuration)) {
        if (tripDuration >= prefMin && tripDuration <= prefMax) {
          score += 15;
          reasons.push('Duration matches your preference');
        } else {
          // Partial points for close duration match
          const averagePrefDuration = (prefMin + prefMax) / 2;
          const difference = Math.abs(tripDuration - averagePrefDuration);
          if (difference <= 2) { // Within 2 days
            score += 7;
            reasons.push('Duration is close to your preference');
          }
        }
      }
    }
  }

  return {
    score,
    matchReason: reasons.join('. ')
  };
}

// Get matched trips from Firebase
export async function getMatchedTrips(preferences: TripFormData): Promise<TripResult[]> {
  console.log('Getting matched trips with preferences:', JSON.stringify(preferences, null, 2));
  const allTrips = await getAllTrips();
  console.log('Got all trips, length:', allTrips.length);
  
  // Score all trips
  const scoredTrips: ScoredTrip[] = allTrips.map(trip => {
    const { score, matchReason } = calculateTripScore(trip, preferences);
    return {
      ...trip,
      matchScore: score,
      matchReason,
      hasRegionMatch: score > 0 // If score > 0, it means region matched
    };
  });

  // Filter out trips with no region match (score of 0)
  let relevantTrips = scoredTrips.filter(trip => trip.matchScore > 0);
  console.log('Relevant trips after scoring:', relevantTrips.length);
  if (relevantTrips.length > 0) {
    console.log('Sample matched trip:', JSON.stringify(relevantTrips[0], null, 2));
  }

  // Sort by score
  relevantTrips.sort((a, b) => b.matchScore - a.matchScore);

  // Return top matches, but ensure they all have region match
  return relevantTrips.slice(0, 6);
}

// Generate a reason for why this trip matches the preferences
function getMatchReason(trip: TripResult, preferences: TripFormData): string {
  const reasons: string[] = [];

  // Region match
  const matchedRegions = preferences.regions
    .filter(region => hasRegionInRoute(trip, region.value))
    .map(region => region.label);
  if (matchedRegions.length > 0) {
    reasons.push(`Includes your preferred ${matchedRegions.length > 1 ? 'regions' : 'region'}: ${matchedRegions.join(', ')}`);
  }

  // Theme match
  const matchedThemes = preferences.themes
    .filter(theme => 
      trip.themes.some(tripTheme => 
        tripTheme.toLowerCase().includes(theme.value.toLowerCase()) ||
        theme.value.toLowerCase().includes(tripTheme.toLowerCase())
      )
    )
    .map(theme => theme.label);
  if (matchedThemes.length > 0) {
    reasons.push(`Matches your interests in ${matchedThemes.join(', ')}`);
  }

  // Duration match
  if (preferences.duration) {
    const prefDuration = parseInt(preferences.duration.value);
    if (prefDuration >= trip.duration.min && prefDuration <= trip.duration.max) {
      reasons.push(`Fits your preferred duration of ${prefDuration} days`);
    }
  }

  if (reasons.length === 0) {
    return "This trip provides a different perspective for your consideration.";
  }

  return reasons.join('. ');
}