import React from 'react';
import { theme } from '../../styles/theme';

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold text-[#D1683F] mb-8">Terms of Service</h1>
      
      <div className="space-y-6 text-gray-700">
        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">1. Acceptance of Terms</h2>
          <p>By accessing or using Baba Camper's services, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">2. Service Description</h2>
          <p>Baba Camper provides:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>AI-powered trip recommendations</li>
            <li>Route planning services</li>
            <li>Travel information and guides</li>
            <li>User account management</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">3. User Responsibilities</h2>
          <p>Users must:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Provide accurate information</li>
            <li>Maintain account security</li>
            <li>Comply with local laws and regulations</li>
            <li>Use the service responsibly</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">4. Intellectual Property</h2>
          <p>All content and materials available through our service are protected by:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Copyright laws</li>
            <li>Trademark rights</li>
            <li>Other proprietary rights</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">5. Limitation of Liability</h2>
          <p>Baba Camper is not liable for:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Accuracy of travel information</li>
            <li>Third-party services or content</li>
            <li>User-generated content</li>
            <li>Service interruptions</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">6. Account Termination</h2>
          <p>We reserve the right to terminate accounts for:</p>
          <ul className="list-disc ml-6 mt-2 space-y-2">
            <li>Violation of these terms</li>
            <li>Fraudulent activity</li>
            <li>Abusive behavior</li>
            <li>Extended inactivity</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">7. Changes to Terms</h2>
          <p>We may modify these terms at any time. Continued use of the service constitutes acceptance of modified terms.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">8. Governing Law</h2>
          <p>These terms are governed by the laws of Morocco. Any disputes shall be resolved in the courts of Morocco.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#79BFAF] mb-4">9. Contact Information</h2>
          <p>For any questions regarding these terms, contact us at:</p>
          <p className="mt-2">legal@babacamper.com</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
