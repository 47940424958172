import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from './AuthModal';
import { theme } from '../styles/theme';

const LandingPage = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (currentUser) {
      navigate('/planner');
    } else {
      setShowAuthModal(true);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F6E6CC] to-white">
      <div className="container mx-auto px-4 py-16 md:py-24">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-[#D1683F] mb-6"
              style={{ fontFamily: theme.typography.fontFamily.primary }}>
            Discover Your Perfect Moroccan Adventure
          </h1>
          <p className="text-xl md:text-2xl text-gray-700 mb-12"
             style={{ fontFamily: theme.typography.fontFamily.secondary }}>
            Plan your dream road trip through Morocco's stunning landscapes with personalized recommendations
          </p>
          <button
            onClick={handleGetStarted}
            className="bg-[#79BFAF] text-white px-8 py-4 rounded-lg text-lg font-semibold
                     hover:bg-[#79BFAF]/90 transition-all duration-300
                     focus:outline-none focus:ring-2 focus:ring-[#79BFAF] focus:ring-offset-2"
          >
            Start Planning Your Journey
          </button>
        </div>

        {/* Features Section */}
        <div className="grid md:grid-cols-3 gap-8 mt-24">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h3 className="text-xl font-semibold text-[#D1683F] mb-4">
              AI-Powered Recommendations
            </h3>
            <p className="text-gray-600">
              Get personalized trip suggestions based on your preferences and travel style
            </p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h3 className="text-xl font-semibold text-[#D1683F] mb-4">
              Interactive Maps
            </h3>
            <p className="text-gray-600">
              Explore detailed routes and discover hidden gems along your journey
            </p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h3 className="text-xl font-semibold text-[#D1683F] mb-4">
              Local Insights
            </h3>
            <p className="text-gray-600">
              Access authentic recommendations and cultural tips from local experts
            </p>
          </div>
        </div>
      </div>

      {showAuthModal && (
        <AuthModal onClose={() => setShowAuthModal(false)} />
      )}
    </div>
  );
};

export default LandingPage;
