import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface AuthModalProps {
  onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ onClose }) => {
  const { signInWithGoogle } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      onClose();
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-semibold leading-6 text-[#D1683F] text-center mb-4"
                >
                  Sign in to Baba Camper
                </Dialog.Title>
                <div className="mt-4">
                  <p className="text-sm text-gray-600 text-center mb-6">
                    Continue with your Google account to start planning your Moroccan adventure
                  </p>
                  <button
                    onClick={handleGoogleSignIn}
                    className="w-full flex items-center justify-center gap-3 bg-white px-6 py-3 border-2 border-[#79BFAF] rounded-lg
                             hover:bg-[#79BFAF]/5 transition-all duration-300 font-medium text-gray-700
                             focus:outline-none focus:ring-2 focus:ring-[#79BFAF] focus:ring-offset-2"
                  >
                    <img 
                      src="https://www.google.com/favicon.ico" 
                      alt="" 
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                    Continue with Google
                  </button>
                  <p className="mt-4 text-xs text-gray-500 text-center">
                    By continuing, you agree to our{' '}
                    <a href="/terms" className="text-[#D1683F] hover:underline">Terms of Service</a>
                    {' '}and{' '}
                    <a href="/privacy" className="text-[#D1683F] hover:underline">Privacy Policy</a>
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AuthModal;
